<template>
  <div class="max-w-full my-4"> <!-- FOOTER START -->
    <i class="pi pi-gift align-middle" style="color: #FACC2E; padding-right: 2px;" />
    <span style="color: #FACC2E; font-size: 1rem;">Special Day</span>
  </div> <!-- FOOTER END -->
</template>

<script>

export default {
  name: "TopSixFooter"
}
</script>

<style scoped>

</style>