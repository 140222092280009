<template>
  <div class="max-w-full"> <!-- HEADER START -->
    <div>
      <Menubar :model="this.$root.$router.options.routes">
        <template #item="{ item, props }">
          <router-link v-if="item" v-slot="{ href, navigate }" :to="item.path" custom>
            <a v-ripple :href="href" v-bind="props.action" @click="navigate">
              <span class="ml-2">{{ item.name }}</span>
            </a>
          </router-link>
        </template>
      </Menubar>
    </div>

    <div class="flex flex-row">
      <div class="basis-1/4 columns-logo">
        <img alt="topsix_logo" class="w-full" :src="state.domain + '/topsiximg/topsix_logo.png'" />
      </div>
      <div class="basis-1/2">
        <p class="text-2xl font-bold mt-7">로또 번호 생성기</p>
      </div>
    </div>
    <div>
      <Message class="text-left" :closable="false" severity="warn" icon="pi pi-info-circle">
        해당 웹사이트는 로또 번호 예측 웹사이트가 아닙니다.<br>
        로또 번호를 즐겁게 조합하고 만들어볼 수 있는 웹사이트 입니다.<br>
        지나친 과몰입이 아닌 정해진 만큼의 건전한 복권 생활을 권장합니다.
      </Message>
    </div>
    <div>
      <Message class="text-left" :closable="false" severity="info" icon="pi pi-info-circle">
        매일 저녁 11시에 최신 로또 정보가 반영됩니다.
      </Message>
    </div>
  </div> <!-- HEADER END -->
</template>

<script>

import { reactive } from "vue";

export default {
  name: "TopSixHeader",
  setup () {
    const state = reactive({
      domain: process.env.VUE_APP_API_BASE_DOMAIN
    })

    return {
      state
    }
  }
}
</script>

<style scoped>

</style>