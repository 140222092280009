<template>
  <div class="container">
    <top-six-header />
    <div class="py-1.5 min-[1000px]:flex flex-wrap justify-center">
      <div className="kakao-adfit-top" />
    </div>
    <router-view className="kakao-adfit-adfit1" />
    <div class="py-1.5 min-[1000px]:flex flex-wrap justify-center">
      <div className="kakao-adfit-bottom" />
    </div>
    <top-six-footer />

    <ScrollTop />
    <div className="kakao-adfit" />
  </div>
</template>

<script>
import TopSixHeader from './components/topsix/TopSixHeader.vue'
import TopSixFooter from './components/topsix/TopSixFooter.vue'
import {onMounted, reactive} from "vue";

export default {
  name: 'App',
  components: {
    TopSixHeader,
    TopSixFooter
  },
  setup () {
    const state = reactive({
      domain: process.env.VUE_APP_API_BASE_DOMAIN
    })
    onMounted(() => {
      kakaoAdfit();
    })

    const kakaoAdfit = () => {
      let scr = document.createElement('script');

      scr.async = 'true';
      scr.type = "text/javascript";
      scr.src = "//t1.daumcdn.net/kas/static/ba.min.js";

      document.querySelector('.kakao-adfit').appendChild(scr);

      let adfitDatas = [
        {
          dataAdUnit: 'DAN-Y5pevmOVwWxz4dwv',
          className: '.kakao-adfit-top'
        },
        {
          dataAdUnit: 'DAN-rR72lU2OJj0smVlb',
          className: '.kakao-adfit-bottom'
        }
      ];

      for (let i = 0; i < adfitDatas.length; i++) {
        let ins = document.createElement('ins');

        ins.className = 'kakao_ad_area';
        ins.style = "display:none; width:100%;";
        ins.setAttribute('data-ad-width', '300');
        ins.setAttribute('data-ad-height', '250');
        ins.setAttribute('data-ad-unit', adfitDatas[i].dataAdUnit);

        document.querySelector(adfitDatas[i].className).appendChild(ins);
      }
    }

    return {
      state,
      kakaoAdfit
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
