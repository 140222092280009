import {createApp} from 'vue'
import App from './App.vue'
import router from './routers/index.js'

import PrimeVue from 'primevue/config'; // PrimeVue Component Design Library
import 'primeicons/primeicons.css'      // PrimeVue Icons
import Lara from '@/presets/lara';      // Tailwind CSS (Lara)
import './style.css'
//import 'primevue/resources/themes/aura-light-green/theme.css' // Theme
//import 'primevue/resources/primevue.css' // Theme

//import 'bootstrap/dist/css/bootstrap.min.css'
//import 'bootstrap'
// PrimeVue Component Setup
import Button from 'primevue/button';
import SelectButton from 'primevue/selectbutton';
import InputText from 'primevue/inputtext';
import Slider from 'primevue/slider';
import Checkbox from 'primevue/checkbox';
import Message from 'primevue/message';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ProgressSpinner from 'primevue/progressspinner';
import Card from 'primevue/card';
import Panel from 'primevue/panel';
import Toolbar from 'primevue/toolbar';
import Menubar from 'primevue/menubar';
import ScrollTop from 'primevue/scrolltop';
import Image from 'primevue/image';
import Knob from 'primevue/knob';
import Chip from 'primevue/chip';
import Avatar from 'primevue/avatar';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';

//import axios from 'axios'

//axios.defaults.baseURL = "http://localhost"



const app = createApp(App)
app.use(router)
app.use(PrimeVue, {
    unstyled: true,
    pt: Lara
});
//app.use(PrimeVue)
app.component('Button', Button)
app.component('SelectButton', SelectButton)
app.component('InputText', InputText)
app.component('Slider', Slider)
app.component('Checkbox', Checkbox)
app.component('Message', Message)
app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('ProgressSpinner', ProgressSpinner)
app.component('Card', Card)
app.component('Panel', Panel)
app.component('Toolbar', Toolbar)
app.component('Menubar', Menubar)
app.component('ScrollTop', ScrollTop)
app.component('Image', Image)
app.component('Knob', Knob)
app.component('Chip', Chip)
app.component('Avatar', Avatar)
app.component('Badge', Badge)
app.directive('badge', BadgeDirective);
//app.use(axios)

//app.config.globalProperties.$axios = axios;

app.mount('#app')