<template>
  <div> <!-- BODY START -->
    <div class="py-7 bg-dark-lemon">
      <div class="text-2xl font-bold text-center align-middle">
        <span v-if="isNotEmpty(state.lastSeq)">{{state.lastSeq}}</span>회 당첨번호
      </div>
      <span v-if="isNotEmpty(state.lastWinningNumbers)">
        <span v-for="(data, index) in state.lastWinningNumbers" :key="index">
          <Avatar :label="String(data)" class="pr-0.5 mr-2" style="background-color: #F5141E; color: #FFFFFF;" shape="circle" />
        </span>
      </span>
      <span v-if="isNotEmpty(state.lastBonusNumber)">
        <span class="pl-1 pr-2">
          <i class="pi pi-plus"></i>
        </span>
        <Avatar :label="String(state.lastBonusNumber)" class="mr-2" style="background-color: #0040FF; color: #FFFFFF;" shape="circle" />
      </span>

      <div class="pt-5 text-lg font-bold text-center align-middle" v-if="isNotEmpty(state.lastFirstWinamnt)">
        1등 당첨 금액: {{currencyWon()}}원
      </div>
      <div class="text-lg font-bold text-center align-middle" v-if="isNotEmpty(state.lastFirstPrzwnerCo)">
        1등 당첨자 수: {{state.lastFirstPrzwnerCo}}명
      </div>
    </div>

    <div v-if="state.isLoading">
      <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="8" animationDuration=".5s" aria-label="Loading" />
    </div>

    <div class="py-14">
      <p v-bind:class="state.headerClasses">최근 당첨 번호</p>

      <span class="flex justify-center">
        <SelectButton v-model="state.latestWinningNumbersLimit" :options="state.latestWinningNumbersLimitOptions" aria-labelledby="basic" />
      </span>
      <span v-for="(data, index) in state.latestWinningNumbers.slice(0, state.latestWinningNumbersLimit)" :key="index">
        <div class="my-1">
          <span class="text-lg font-bold">{{data.seq}}회 </span>
          <span v-if="isNotEmpty(data.winningNumbers)">
            <span v-for="(number, index) in data.winningNumbers" :key="index">
              <Avatar :label="String(number)" class="pr-0.5" style="background-color: #F5141E; color: #FFFFFF;" shape="circle" />
            </span>
          </span>
          <span v-if="isNotEmpty(data.bnusNo)">
            <span class="pl-1 pr-1">
              <i class="pi pi-plus"></i>
            </span>
            <Avatar :label="String(data.bnusNo)" style="background-color: #0040FF; color: #FFFFFF;" shape="circle" />
          </span>
          <br>
        </div>
      </span>
    </div>

    <div className="kakao-adfit-child3" />

    <div class="py-14">
      <p v-bind:class="state.headerClasses">역대 당첨 번호 통계 리스트</p>

      <div class="area-center">
        <DataTable :value="state.winningNumberSortedDatas" showGridlines scrollable scrollHeight="800px">
          <Column field="placing" style="text-align: center" sortable
                  header="당첨 많이 된 순위" headerClass="table-header-center bg-rose-500 text-rose-50"></Column>
          <Column field="number" style="text-align: center" sortable
                  header="당첨 번호" headerClass="table-header-center bg-rose-500 text-rose-50">
            <template #body="slotProps">
              <Avatar :label="String(slotProps.data.number)" style="background-color: rgb(225 29 72); color: rgb(255 241 242);" shape="circle" />
            </template>
          </Column>
          <Column field="count" style="text-align: center" sortable
                  header="당첨된 횟수" headerClass="table-header-center bg-rose-500 text-rose-50"></Column>
          <Column field="bonusNumber" style="text-align: center" sortable
                  header="보너스 당첨된 횟수" headerClass="table-header-center bg-rose-500 text-rose-50"></Column>
          <Column field="countBonusNumberSum" style="text-align: center" sortable
                  header="당첨된 횟수 + 보너스 당첨된 횟수" headerClass="table-header-center bg-rose-500 text-rose-50"></Column>

          <template #empty>데이터가 존재하지 않습니다.</template>
        </DataTable>
      </div>
    </div>

    <div className="kakao-adfit-child4" />

    <div class="py-14">
      <p v-bind:class="state.headerClasses">역대 당첨 번호 한눈에 보기</p>

      <div>
        <Card style="background-color: rgb(255 247 237);">
          <template #title>
            <div class="text-left flex align-items-center gap-2">
              <Avatar :image="state.domain + '/topsiximg/lotto_topsix.png'" size="large" shape="circle" />
              <span class="text-base pt-3 inline-block align-middle">역대 당첨 수 1 ~ 15위</span>
            </div>
          </template>
          <template #content>
            <span v-if="isNotEmpty(state.winningNumberSortedDatas) && state.winningNumberSortedDatas.length > 0" class="flex flex-wrap flex-row align-items-center justify-center">
              <span v-for="(data, index) in state.winningNumberSortedDatas.slice(0,14)" :key="index">
                <Avatar :label="String(data.number)" class="mr-2" style="background-color: rgb(252 211 77); color: rgb(10 10 10);" shape="circle" /><br>
                {{data.count}}
              </span>
            </span>
          </template>
        </Card>
      </div>

      <div class="pt-2">
        <Card style="background-color: rgb(255 247 237);">
          <template #title>
            <div class="text-left flex align-items-center gap-2">
              <Avatar :image="state.domain + '/topsiximg/lotto_topsix.png'" size="large" shape="circle" />
              <span class="text-base pt-3">역대 당첨 수 16 ~ 30위</span>
            </div>
          </template>
          <template #content>
            <span v-if="isNotEmpty(state.winningNumberSortedDatas) && state.winningNumberSortedDatas.length > 0" class="flex flex-wrap flex-row align-items-center justify-center">
              <span v-for="(data, index) in state.winningNumberSortedDatas.slice(15,29)" :key="index">
                <Avatar :label="String(data.number)" class="mr-2" style="background-color: rgb(252 211 77); color: rgb(10 10 10);" shape="circle" /><br>
                {{data.count}}
              </span>
            </span>
          </template>
        </Card>
      </div>

      <div class="pt-2">
        <Card style="background-color: rgb(255 247 237);">
          <template #title>
            <div class="text-left flex align-items-center gap-2">
              <Avatar :image="state.domain + '/topsiximg/lotto_topsix.png'" size="large" shape="circle" />
              <span class="text-base pt-3">역대 당첨 수 31 ~ 45위</span>
            </div>
          </template>
          <template #content>
            <span v-if="isNotEmpty(state.winningNumberSortedDatas) && state.winningNumberSortedDatas.length > 0" class="flex flex-wrap flex-row align-items-center justify-center">
              <span v-for="(data, index) in state.winningNumberSortedDatas.slice(30,44)" :key="index">
                <Avatar :label="String(data.number)" class="mr-2" style="background-color: rgb(252 211 77); color: rgb(10 10 10);" shape="circle" /><br>
                {{data.count}}
              </span>
            </span>
          </template>
        </Card>
      </div>
    </div>
  </div> <!-- BODY END -->
</template>

<script>
import {onMounted, reactive} from 'vue'
import {apiInstance} from '../../api/index.js';

const api = apiInstance();

export default {
  name: "LotteryInfo",
  setup () {
    const state = reactive({
      isLoading: false,
      lotteryNumbers: [],
      lastSeq: '',
      lastWinningNumbers: [],
      lastBonusNumber: '',
      lastFirstWinamnt: '',
      lastFirstPrzwnerCo: '',
      latestWinningNumbers: [],
      latestWinningNumbersLimit: 10,
      latestWinningNumbersLimitOptions: [10, 20, 30, 40, 50],
      winningNumberSortedDatas: [],
      randomNumberDatas: [],
      luckyNumber: '',
      checkedUserNumbers: [],
      luckyUserNumbers: [],
      headerClasses: ['text-2xl', 'font-bold'],
      domain: process.env.VUE_APP_API_BASE_DOMAIN
    })

    onMounted(() => {
      getLotteryData();
      childAdfit();
    })

    const getLotteryData = () => {
      state.isLoading = true;
      api.get("/api/lottery/winning/info").then(function(res) {
        state.isLoading = false;
        let resData = res.data
        state.lastSeq = resData['lastSeq']
        state.lastWinningNumbers = resData['lastWinningNumbers']
        state.lastBonusNumber = resData['lastBonusNumber']
        state.lastFirstWinamnt = resData['lastFirstWinamnt']
        state.lastFirstPrzwnerCo = resData['lastFirstPrzwnerCo']
        state.latestWinningNumbers = resData['latestWinningNumbers']
        state.winningNumberSortedDatas = resData['winningNumberSortedDatas']
      }).finally(() => {state.isLoading = false;});
    }

    const currencyWon = () => {
      if (isNotEmpty(state.lastFirstWinamnt)) {
        return state.lastFirstWinamnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return '';
    }

    const isNotEmpty = (arr) => {
      if (arr != null && arr != undefined) {
        return true;
      }
      return false;
    }

    const childAdfit = () => {
      let adfitDatas = [
        {
          dataAdUnit: 'DAN-emwLAmT28pooVV8E',
          className: '.kakao-adfit-child3'
        },
        {
          dataAdUnit: 'DAN-rERRZBFVmzSog1MP',
          className: '.kakao-adfit-child4'
        }
      ];

      for (let i = 0; i < adfitDatas.length; i++) {
        let ins = document.createElement('ins');

        ins.className = 'kakao_ad_area';
        ins.style = "display:none; width:100%;";
        ins.setAttribute('data-ad-width', '300');
        ins.setAttribute('data-ad-height', '250');
        ins.setAttribute('data-ad-unit', adfitDatas[i].dataAdUnit);

        document.querySelector(adfitDatas[i].className).appendChild(ins);
      }
    }

    return {
      getLotteryData,
      currencyWon,
      isNotEmpty,
      childAdfit,
      state
    }
  }
}
</script>

<style scoped>
  .area-center {
    display: inline-flex;
    justify-items: center;
    max-width: 1000px;
  }

  .small-area-center {
    display: inline-flex;
    justify-items: center;
    max-width: 300px;
  }

  .table-header-center {
    justify-items: center;
    align-items: center;
  }
</style>