<template>
  <div> <!-- BODY START -->
    <div class="py-7 bg-dark-lemon">
      <div class="text-2xl font-bold text-center align-middle">
        <span v-if="isNotEmpty(state.lastSeq)">{{ state.lastSeq }}</span>회 당첨번호
      </div>
      <span v-if="isNotEmpty(state.lastWinningNumbers)">
        <span v-for="(data, index) in state.lastWinningNumbers" :key="index">
          <Avatar :label="String(data)" class="pr-0.5 mr-2" style="background-color: #F5141E; color: #FFFFFF;"
                  shape="circle"/>
        </span>
      </span>
      <span v-if="isNotEmpty(state.lastBonusNumber)">
        <span class="pl-1 pr-2">
          <i class="pi pi-plus"></i>
        </span>
        <Avatar :label="String(state.lastBonusNumber)" class="mr-2" style="background-color: #0040FF; color: #FFFFFF;"
                shape="circle"/>
      </span>

      <div class="pt-5 text-lg font-bold text-center align-middle" v-if="isNotEmpty(state.lastFirstWinamnt)">
        1등 당첨 금액: {{currencyWon()}}원
      </div>
      <div class="text-lg font-bold text-center align-middle" v-if="isNotEmpty(state.lastFirstPrzwnerCo)">
        1등 당첨자 수: {{state.lastFirstPrzwnerCo}}명
      </div>
    </div>

    <div v-if="state.isLoading">
      <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="8" animationDuration=".5s" aria-label="Loading"/>
    </div>

    <div class="py-14 flex justify-center">
      <Card class="max-w-md" style="overflow: hidden">
        <template #header>
          <img alt="goodluck" :src="state.domain + '/topsiximg/lotto_topsix.png'"/>
        </template>
        <template #title>나만의 조건으로 행운 번호 뽑기</template>
        <template #subtitle>이번 주 행운 번호를 만들어보세요.</template>
        <template #content>
          <div class="py-7 font-bold text-orange-100" style="background-color: #2C3E50;">
            <Checkbox v-model="state.excludeWinningNumbers" inputId="excludeWinningNumbers" binary/>
            <label for="excludeWinningNumbers" class="ml-2"> 마지막 주 당첨 번호 제외 </label>
            <br/><br/>
            역대 당첨 횟수 적은 숫자 {{ state.tinyWinningNumber }}개 제외<br/>
            <span class="flex justify-center pt-2">
              <Slider v-model="state.tinyWinningNumber" :min="0" :max="20"/>
            </span>
          </div>
          <div class="pt-7">
            <span v-if="isNotEmpty(state.randomNumberByConditionDatas) && state.randomNumberByConditionDatas.length > 0">
              <span v-for="(data, index) in state.randomNumberByConditionDatas" :key="index">
                <span class="pr-0.5">
                  <Avatar :label="String(data.number)" class="mr-2" style="background-color: #FACC2E; color: #000000;"
                          shape="circle"/>
                </span>
              </span>
            </span>
            <span v-else class="text-zinc-400">
              <i class="pi pi-heart"></i> 번호를 뽑아주세요.
            </span>
          </div>
        </template>
        <template #footer>
          <div class="flex gap-3 mt-1">
            <Button label="번호 뽑기" icon="pi pi-gift" severity="success" class="w-full"
                    @click="generateRandomNumberByConditionDatas"/>
          </div>
        </template>
      </Card>
    </div>

    <div className="kakao-adfit-child1" />

    <div class="py-14 flex justify-center">
      <Card class="max-w-md" style="overflow: hidden">
        <template #header>
          <img alt="goodluck" :src="state.domain + '/topsiximg/good_luck1.png'"/>
        </template>
        <template #title>로또 번호 6자리 랜덤 뽑기</template>
        <template #subtitle>이번 주 행운의 로또 번호를 랜덤으로 뽑아보세요.</template>
        <template #content>
          <span v-if="isNotEmpty(state.randomNumberDatas) && state.randomNumberDatas.length > 0">
            <span v-for="(data, index) in state.randomNumberDatas" :key="index">
              <span class="pr-0.5">
                <Avatar :label="String(data.number)" class="mr-2" style="background-color: #FACC2E; color: #000000;"
                        shape="circle"/>
              </span>
            </span>
          </span>
          <span v-else class="text-zinc-400">
            <i class="pi pi-heart"></i> 번호를 뽑아주세요.
          </span>
        </template>
        <template #footer>
          <div class="flex gap-3 mt-1">
            <Button label="번호 뽑기" icon="pi pi-gift" severity="success" class="w-full"
                    @click="generateRandomNumberDatas"/>
          </div>
        </template>
      </Card>
    </div>

    <div className="kakao-adfit-child2" />

    <div class="py-14 flex justify-center">
      <Card class="max-w-md" style="overflow: hidden">
        <template #header>
          <img alt="question" :src="state.domain + '/topsiximg/question.png'"/>
        </template>
        <template #title>한 자리 로또 번호 뽑기</template>
        <template #subtitle>행운 번호를 랜덤으로 뽑아보세요.</template>
        <template #content>
          <div class="flex justify-center">
            <Knob v-model="state.luckyNumber" :size="150" :min="0" :max="45" valueColor="MediumTurquoise"
                  rangeColor="SlateGray"/>
          </div>
        </template>
        <template #footer>
          <div class="flex gap-3 mt-1">
            <Button label="번호 뽑기" icon="pi pi-heart" severity="success" class="w-full" @click="generateLuckyNumber"/>
          </div>
        </template>
      </Card>
    </div>

    <div class="py-14 flex justify-center">
      <Card class="max-w-md" style="overflow: hidden">
        <template #header>
          <img alt="everything" :src="state.domain + '/topsiximg/everything.png'"/>
        </template>
        <template #title>나만의 번호 선택 후 로또 번호 뽑기</template>
        <template #subtitle>나만의 행운 숫자로 로또 번호를 만들어 보세요.</template>
        <template #content>
          <div>
            <Button class="rounded-3xl" icon="pi pi-refresh" @click="refreshCheckedUserNumbers"/>
          </div>
          <div class="py-9">
            <span v-if="isNotEmpty(state.luckyUserNumbers) && state.luckyUserNumbers.length > 0">
              <Toolbar style="border-radius: 3rem; padding: 1rem 1rem 1rem 1.5rem">
                <template #center>
                <span v-for="(number, index) in state.luckyUserNumbers" :key="index">
                  <Avatar :label="String(number)" class="mr-2"
                          style="background-color: rgb(244 114 182); color: rgb(10 10 10);" shape="circle"/>
                </span>
                </template>
              </Toolbar>
            </span>
            <span v-else class="text-zinc-400">
              <i class="pi pi-heart"></i> 번호를 뽑아주세요.
            </span>
          </div>
          <div>
            <Button label="번호 뽑기" icon="pi pi-user-edit" severity="success" class="w-full"
                    @click="generateLuckyUserNumbers"/>
          </div>
        </template>
        <template #footer>
          <div class="card flex flex-wrap justify-center gap-3">
            <span v-for="(number, index) in 45" :key="index">
              <span class="flex align-items-center">
                <Checkbox v-model="state.checkedUserNumbers" inputId="inputId{{number}}" name="inputId{{number}}"
                          :value="number"
                          :disabled="!state.checkedUserNumbers.includes(number) && state.checkedUserNumbers.length >= 6"/>
                <Avatar :label="String(number)" class="mr-2"
                        style="background-color: rgb(244 114 182); color: rgb(10 10 10);" shape="circle"/>
              </span>
            </span>
          </div>
        </template>
      </Card>
    </div>

    <div class="py-14">
      <p v-bind:class="state.headerClasses">구간 별 번호 뽑기</p>

      <div class="flex justify-center pt-4">
        <table class="table-auto border-collapse border border-slate-300 w-full">
          <thead>
          <tr>
            <th class="border border-slate-300 w-2/12" style="background-color: rgb(255 241 242);">
                <span class="flex justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-stars"
                       viewBox="0 0 16 16">
                    <path
                        d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.73 1.73 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.73 1.73 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.73 1.73 0 0 0 3.407 2.31zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z"/>
                  </svg>
                </span>
            </th>
            <th class="border border-slate-300 w-10/12" colspan="5" style="background-color: rgb(255 241 242);">숫자 범위
            </th>
          </tr>
          <tr v-if="isNotEmpty(state.winningNumberSortedDatas) && state.winningNumberSortedDatas.length > 0">
            <th class="border border-slate-300" style="background-color: rgb(255 241 242);">당첨 순위 범위</th>
            <th class="border border-slate-300 align-middle" style="background-color: rgb(255 241 242);">
              1~9
              <div>
                <Button text rounded size="small" style="background-color: rgb(251 113 133); color: rgb(10 10 10);"
                        @click="generateOneRandomNumber">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-shuffle" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.624 9.624 0 0 0 7.556 8a9.624 9.624 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.595 10.595 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.624 9.624 0 0 0 6.444 8a9.624 9.624 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5z"/>
                    <path
                        d="M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192zm0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192z"/>
                  </svg>
                </Button>
              </div>
              <div>{{ state.oneRandomNumber }}</div>
            </th>
            <th class="border border-slate-300 align-middle" style="background-color: rgb(255 241 242);">
              10~19
              <div>
                <Button text rounded size="small" style="background-color: rgb(251 113 133); color: rgb(10 10 10);"
                        @click="generateTenRandomNumber">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-shuffle" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.624 9.624 0 0 0 7.556 8a9.624 9.624 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.595 10.595 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.624 9.624 0 0 0 6.444 8a9.624 9.624 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5z"/>
                    <path
                        d="M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192zm0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192z"/>
                  </svg>
                </Button>
              </div>
              <div>{{ state.tenRandomNumber }}</div>
            </th>
            <th class="border border-slate-300 align-middle" style="background-color: rgb(255 241 242);">
              20~29
              <div>
                <Button text rounded size="small" style="background-color: rgb(251 113 133); color: rgb(10 10 10);"
                        @click="generateTwentyRandomNumber">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-shuffle" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.624 9.624 0 0 0 7.556 8a9.624 9.624 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.595 10.595 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.624 9.624 0 0 0 6.444 8a9.624 9.624 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5z"/>
                    <path
                        d="M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192zm0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192z"/>
                  </svg>
                </Button>
              </div>
              <div>{{ state.twentyRandomNumber }}</div>
            </th>
            <th class="border border-slate-300 align-middle" style="background-color: rgb(255 241 242);">
              30~39
              <div>
                <Button text rounded size="small" style="background-color: rgb(251 113 133); color: rgb(10 10 10);"
                        @click="generateThirtyRandomNumber">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-shuffle" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.624 9.624 0 0 0 7.556 8a9.624 9.624 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.595 10.595 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.624 9.624 0 0 0 6.444 8a9.624 9.624 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5z"/>
                    <path
                        d="M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192zm0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192z"/>
                  </svg>
                </Button>
              </div>
              <div>{{ state.thirtyRandomNumber }}</div>
            </th>
            <th class="border border-slate-300 align-middle" style="background-color: rgb(255 241 242);">
              40~45
              <div>
                <Button text rounded size="small" style="background-color: rgb(251 113 133); color: rgb(10 10 10);"
                        @click="generateFortyRandomNumber">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-shuffle" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.624 9.624 0 0 0 7.556 8a9.624 9.624 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.595 10.595 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.624 9.624 0 0 0 6.444 8a9.624 9.624 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5z"/>
                    <path
                        d="M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192zm0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192z"/>
                  </svg>
                </Button>
              </div>
              <div>{{ state.fortyRandomNumber }}</div>
            </th>
          </tr>
          <tr v-else>
            <th class="border border-slate-300 align-middle" colspan="6">행운의 번호를 선택하세요.</th>
          </tr>
          </thead>
          <tbody>
          <tr><!-- 숫자 순위: 1 ~ 15 -->
            <td class="border border-slate-300 font-bold align-middle" style="background-color: rgb(255 241 242);">
              1~15위
              <div>
                <Button text rounded severity="info" size="small"
                        style="background-color: rgb(251 113 133); color: rgb(10 10 10);"
                        @click="generatePartOneRandomNumber">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-shuffle" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.624 9.624 0 0 0 7.556 8a9.624 9.624 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.595 10.595 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.624 9.624 0 0 0 6.444 8a9.624 9.624 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5z"/>
                    <path
                        d="M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192zm0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192z"/>
                  </svg>
                </Button>
              </div>
              <div>{{ state.partOneRandomNumber }}</div>
            </td>
            <td class="border border-slate-300 align-middle">
              <div class="pt-4 pb-2.5" v-if="isNotEmpty(state.onePartOneList) && state.onePartOneList.length > 0"><!-- 숫자 범위: 1 ~ 9 -->
                <div v-for="(data, index) in state.onePartOneList" :key="index">
                  <div class="pb-1.5">
                    <Avatar :label="String(data.number)" :style="isWinningBonusNumberColor" shape="circle"/>
                    <br>
                    {{ data.count }}
                  </div>
                </div>
              </div>
            </td>
            <td class="border border-slate-300 align-middle">
              <div class="pt-4 pb-2.5" v-if="isNotEmpty(state.tenPartOneList) && state.tenPartOneList.length > 0"><!-- 숫자 범위: 10-19 -->
                <div v-for="(data, index) in state.tenPartOneList" :key="index">
                  <div class="pb-1.5">
                    <Avatar :label="String(data.number)" :style="isWinningBonusNumberColor" shape="circle"/>
                    <br>
                    {{ data.count }}
                  </div>
                </div>
              </div>
            </td>
            <td class="border border-slate-300 align-middle">
              <div class="pt-4 pb-2.5" v-if="isNotEmpty(state.twentyPartOneList) && state.twentyPartOneList.length > 0"><!-- 숫자 범위: 20-29 -->
                <div v-for="(data, index) in state.twentyPartOneList" :key="index">
                  <div class="pb-1.5">
                    <Avatar :label="String(data.number)" :style="isWinningBonusNumberColor" shape="circle"/>
                    <br>
                    {{ data.count }}
                  </div>
                </div>
              </div>
            </td>
            <td class="border border-slate-300 align-middle">
              <div class="pt-4 pb-2.5" v-if="isNotEmpty(state.thirtyPartOneList) && state.thirtyPartOneList.length > 0"><!-- 숫자 범위: 30-39 -->
                <div v-for="(data, index) in state.thirtyPartOneList" :key="index">
                  <div class="pb-1.5">
                    <Avatar :label="String(data.number)" :style="isWinningBonusNumberColor" shape="circle"/>
                    <br>
                    {{ data.count }}
                  </div>
                </div>
              </div>
            </td>
            <td class="border border-slate-300 align-middle">
              <div class="pt-4 pb-2.5" v-if="isNotEmpty(state.fortyPartOneList) && state.fortyPartOneList.length > 0"><!-- 숫자 범위: 40-45 -->
                <div v-for="(data, index) in state.fortyPartOneList" :key="index">
                  <div class="pb-1.5">
                    <Avatar :label="String(data.number)" :style="isWinningBonusNumberColor" shape="circle"/>
                    <br>
                    {{ data.count }}
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr><!-- 숫자 순위: 16 ~ 30 -->
            <td class="border border-slate-300 font-bold align-middle" style="background-color: rgb(255 241 242);">
              16~30위
              <div>
                <Button text rounded severity="info" size="small"
                        style="background-color: rgb(251 113 133); color: rgb(10 10 10);"
                        @click="generatePartTwoRandomNumber">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-shuffle" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.624 9.624 0 0 0 7.556 8a9.624 9.624 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.595 10.595 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.624 9.624 0 0 0 6.444 8a9.624 9.624 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5z"/>
                    <path
                        d="M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192zm0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192z"/>
                  </svg>
                </Button>
              </div>
              <div>{{ state.partTwoRandomNumber }}</div>
            </td>
            <td class="border border-slate-300 align-middle">
              <div class="pt-4 pb-2.5" v-if="isNotEmpty(state.onePartTwoList) && state.onePartTwoList.length > 0"><!-- 숫자 범위: 1 ~ 9 -->
                <div v-for="(data, index) in state.onePartTwoList" :key="index">
                  <div class="pb-1.5">
                    <Avatar :label="String(data.number)" :style="isWinningBonusNumberColor" shape="circle"/>
                    <br>
                    {{ data.count }}
                  </div>
                </div>
              </div>
            </td>
            <td class="border border-slate-300 align-middle">
              <div class="pt-4 pb-2.5" v-if="isNotEmpty(state.tenPartTwoList) && state.tenPartTwoList.length > 0"><!-- 숫자 범위: 10-19 -->
                <div v-for="(data, index) in state.tenPartTwoList" :key="index">
                  <div class="pb-1.5">
                    <Avatar :label="String(data.number)" :style="isWinningBonusNumberColor" shape="circle"/>
                    <br>
                    {{ data.count }}
                  </div>
                </div>
              </div>
            </td>
            <td class="border border-slate-300 align-middle">
              <div class="pt-4 pb-2.5" v-if="isNotEmpty(state.twentyPartTwoList) && state.twentyPartTwoList.length > 0"><!-- 숫자 범위: 20-29 -->
                <div v-for="(data, index) in state.twentyPartTwoList" :key="index">
                  <div class="pb-1.5">
                    <Avatar :label="String(data.number)" :style="isWinningBonusNumberColor" shape="circle"/>
                    <br>
                    {{ data.count }}
                  </div>
                </div>
              </div>
            </td>
            <td class="border border-slate-300 align-middle">
              <div class="pt-4 pb-2.5" v-if="isNotEmpty(state.thirtyPartTwoList) && state.thirtyPartTwoList.length > 0"><!-- 숫자 범위: 30-39 -->
                <div v-for="(data, index) in state.thirtyPartTwoList" :key="index">
                  <div class="pb-1.5">
                    <Avatar :label="String(data.number)" :style="isWinningBonusNumberColor" shape="circle"/>
                    <br>
                    {{ data.count }}
                  </div>
                </div>
              </div>
            </td>
            <td class="border border-slate-300 align-middle">
              <div class="pt-4 pb-2.5" v-if="isNotEmpty(state.fortyPartTwoList) && state.fortyPartTwoList.length > 0"><!-- 숫자 범위: 40-45 -->
                <div v-for="(data, index) in state.fortyPartTwoList" :key="index">
                  <div class="pb-1.5">
                    <Avatar :label="String(data.number)" :style="isWinningBonusNumberColor" shape="circle"/>
                    <br>
                    {{ data.count }}
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr><!-- 숫자 순위: 31 ~ 45 -->
            <td class="border border-slate-300 font-bold align-middle" style="background-color: rgb(255 241 242);">
              31~45위
              <div>
                <Button text rounded severity="info" size="small"
                        style="background-color: rgb(251 113 133); color: rgb(10 10 10);"
                        @click="generatePartThreeRandomNumber">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-shuffle" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.624 9.624 0 0 0 7.556 8a9.624 9.624 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.595 10.595 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.624 9.624 0 0 0 6.444 8a9.624 9.624 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5z"/>
                    <path
                        d="M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192zm0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192z"/>
                  </svg>
                </Button>
              </div>
              <div>{{ state.partThreeRandomNumber }}</div>
            </td>
            <td class="border border-slate-300 align-middle">
              <div class="pt-4 pb-2.5" v-if="isNotEmpty(state.onePartThreeList) && state.onePartThreeList.length > 0"><!-- 숫자 범위: 1 ~ 9 -->
                <div v-for="(data, index) in state.onePartThreeList" :key="index">
                  <div class="pb-1.5">
                    <Avatar :label="String(data.number)" :style="isWinningBonusNumberColor" shape="circle"/>
                    <br>
                    {{ data.count }}
                  </div>
                </div>
              </div>
            </td>
            <td class="border border-slate-300 align-middle">
              <div class="pt-4 pb-2.5" v-if="isNotEmpty(state.tenPartThreeList) && state.tenPartThreeList.length > 0"><!-- 숫자 범위: 10-19 -->
                <div v-for="(data, index) in state.tenPartThreeList" :key="index">
                  <div class="pb-1.5">
                    <Avatar :label="String(data.number)" :style="isWinningBonusNumberColor" shape="circle"/>
                    <br>
                    {{ data.count }}
                  </div>
                </div>
              </div>
            </td>
            <td class="border border-slate-300 align-middle">
              <div class="pt-4 pb-2.5" v-if="isNotEmpty(state.twentyPartThreeList) && state.twentyPartThreeList.length > 0"><!-- 숫자 범위: 20-29 -->
                <div v-for="(data, index) in state.twentyPartThreeList" :key="index">
                  <div class="pb-1.5">
                    <Avatar :label="String(data.number)" :style="isWinningBonusNumberColor" shape="circle"/>
                    <br>
                    {{ data.count }}
                  </div>
                </div>
              </div>
            </td>
            <td class="border border-slate-300 align-middle">
              <div class="pt-4 pb-2.5" v-if="isNotEmpty(state.thirtyPartThreeList) && state.thirtyPartThreeList.length > 0"><!-- 숫자 범위: 30-39 -->
                <div v-for="(data, index) in state.thirtyPartThreeList" :key="index">
                  <div class="pb-1.5">
                    <Avatar :label="String(data.number)" :style="isWinningBonusNumberColor" shape="circle"/>
                    <br>
                    {{ data.count }}
                  </div>
                </div>
              </div>
            </td>
            <td class="border border-slate-300 align-middle">
              <div class="pt-4 pb-2.5" v-if="isNotEmpty(state.fortyPartThreeList) && state.fortyPartThreeList.length > 0"><!-- 숫자 범위: 40-45 -->
                <div v-for="(data, index) in state.fortyPartThreeList" :key="index">
                  <div class="pb-1.5">
                    <Avatar :label="String(data.number)" :style="isWinningBonusNumberColor" shape="circle"/>
                    <br>
                    {{ data.count }}
                  </div>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div> <!-- BODY END -->
</template>

<script>
import {computed, onMounted, reactive} from 'vue'
import _ from "lodash";
import {apiInstance} from '../../api/index.js';

const api = apiInstance();

export default {
  name: "LotteryWinning",
  setup() {
    const state = reactive({
      isLoading: false,
      lotteryNumbers: [],
      lastSeq: '',
      lastWinningNumbers: [],
      lastBonusNumber: '',
      lastFirstWinamnt: '',
      lastFirstPrzwnerCo: '',
      winningNumberSortedDatas: [],
      randomNumberByConditionDatas: [],
      randomNumberDatas: [],
      luckyNumber: '',
      checkedUserNumbers: [],
      luckyUserNumbers: [],
      excludeWinningNumbers: true,
      tinyWinningNumber: 0,
      onePartOneList: [],
      onePartTwoList: [],
      onePartThreeList: [],
      tenPartOneList: [],
      tenPartTwoList: [],
      tenPartThreeList: [],
      twentyPartOneList: [],
      twentyPartTwoList: [],
      twentyPartThreeList: [],
      thirtyPartOneList: [],
      thirtyPartTwoList: [],
      thirtyPartThreeList: [],
      fortyPartOneList: [],
      fortyPartTwoList: [],
      fortyPartThreeList: [],
      partOneRandomNumber: undefined,
      partTwoRandomNumber: undefined,
      partThreeRandomNumber: undefined,
      oneRandomNumber: undefined,
      tenRandomNumber: undefined,
      twentyRandomNumber: undefined,
      thirtyRandomNumber: undefined,
      fortyRandomNumber: undefined,
      headerClasses: ['text-2xl', 'font-bold'],
      domain: process.env.VUE_APP_API_BASE_DOMAIN
    })

    onMounted( () => {
      getLotteryData();
      childAdfit();
    })

    const isWinningBonusNumberColor = computed(() => ({
      'background-color': 'rgb(252 211 77)',
      'color': 'rgb(10 10 10)'
    }))

    const getLotteryData = () => {
      state.isLoading = true;
      api.get("/api/lottery/winning/info").then(function (res) {
        state.isLoading = false;
        let resData = res.data
        state.lastSeq = resData['lastSeq']
        state.lastWinningNumbers = resData['lastWinningNumbers']
        state.lastBonusNumber = resData['lastBonusNumber']
        state.lastFirstWinamnt = resData['lastFirstWinamnt']
        state.lastFirstPrzwnerCo = resData['lastFirstPrzwnerCo']
        state.winningNumberSortedDatas = resData['winningNumberSortedDatas']
        state.onePartOneList = resData['onePartOneList']
        state.onePartTwoList = resData['onePartTwoList']
        state.onePartThreeList = resData['onePartThreeList']
        state.tenPartOneList = resData['tenPartOneList']
        state.tenPartTwoList = resData['tenPartTwoList']
        state.tenPartThreeList = resData['tenPartThreeList']
        state.twentyPartOneList = resData['twentyPartOneList']
        state.twentyPartTwoList = resData['twentyPartTwoList']
        state.twentyPartThreeList = resData['twentyPartThreeList']
        state.thirtyPartOneList = resData['thirtyPartOneList']
        state.thirtyPartTwoList = resData['thirtyPartTwoList']
        state.thirtyPartThreeList = resData['thirtyPartThreeList']
        state.fortyPartOneList = resData['fortyPartOneList']
        state.fortyPartTwoList = resData['fortyPartTwoList']
        state.fortyPartThreeList = resData['fortyPartThreeList']
      }).finally(() => {
        state.isLoading = false;
      });
    }

    const currencyWon = () => {
      if (isNotEmpty(state.lastFirstWinamnt)) {
        return state.lastFirstWinamnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return '';
    }

    const generateRandomNumberByConditionDatas = () => {
      state.randomNumberByConditionDatas = state.winningNumberSortedDatas.slice();

      let toBeDeletedNumbers = [];
      if (state.tinyWinningNumber > 0) {
        toBeDeletedNumbers = state.randomNumberByConditionDatas.splice(45 - state.tinyWinningNumber, state.tinyWinningNumber);
      }

      let removedCount = toBeDeletedNumbers.length;
      if (state.excludeWinningNumbers) {
        if (state.tinyWinningNumber > 0) {
          for (let i = 0; i < toBeDeletedNumbers.length; i++) {
            if (state.lastWinningNumbers.indexOf(toBeDeletedNumbers[i].number) !== -1) {
              toBeDeletedNumbers.splice(i, 1);
              i--;
            }
          }
        }

        removedCount += 6;
        for (let j = 0; j < state.randomNumberByConditionDatas.length; j++) {
          if (state.lastWinningNumbers.indexOf(state.randomNumberByConditionDatas[j].number) !== -1) {
            state.randomNumberByConditionDatas.splice(j, 1);
            j--;
          }
        }
      }

      state.randomNumberByConditionDatas.length = 45 - removedCount;
      state.randomNumberByConditionDatas = _.sampleSize(state.randomNumberByConditionDatas, 6);
    }

    const generateRandomNumberDatas = () => {
      state.randomNumberDatas = _.sampleSize(state.winningNumberSortedDatas, 6);
    }

    const generateLuckyNumber = () => {
      state.luckyNumber = _.random(1, 45);
    }

    const refreshCheckedUserNumbers = () => {
      state.checkedUserNumbers = []
      state.luckyUserNumbers = []
    }

    const generateLuckyUserNumbers = () => {
      state.lotteryNumbers = _.range(1, 46, 1);
      state.luckyUserNumbers = state.checkedUserNumbers;
      if (state.checkedUserNumbers.length < 1) {
        state.luckyUserNumbers = _.sampleSize(state.lotteryNumbers, 6);
      } else if (state.checkedUserNumbers.length < 6) {
        var randomNumberCount = 6 - state.luckyUserNumbers.length
        state.lotteryNumbers = _.remove(state.lotteryNumbers, function (lotteryNumber) {
          return !state.checkedUserNumbers.includes(lotteryNumber); //remove if color is green
        });
        var randomNumberArr = _.sampleSize(state.lotteryNumbers, randomNumberCount)
        state.luckyUserNumbers = _.concat(state.luckyUserNumbers, randomNumberArr);
      }
    }

    // 순위: 1 ~ 15
    const generatePartOneRandomNumber = () => {
      let numberList = [];
      let partOneList = state.onePartOneList.concat(state.tenPartOneList, state.twentyPartOneList, state.thirtyPartOneList, state.fortyPartOneList);
      if (partOneList) {
        for (var i = 0; i < partOneList.length; i++) {
          numberList.push(partOneList[i].number)
        }
        state.partOneRandomNumber = _.sampleSize(numberList, 1);
      }
    }

    // 순위: 16 ~ 30
    const generatePartTwoRandomNumber = () => {
      let numberList = [];
      let partTwoList = state.onePartTwoList.concat(state.tenPartTwoList, state.twentyPartTwoList, state.thirtyPartTwoList, state.fortyPartTwoList);
      if (partTwoList) {
        for (var i = 0; i < partTwoList.length; i++) {
          numberList.push(partTwoList[i].number)
        }
        state.partTwoRandomNumber = _.sampleSize(numberList, 1);
      }
    }

    // 순위: 31 ~ 45
    const generatePartThreeRandomNumber = () => {
      let numberList = [];
      let partThreeList = state.onePartThreeList.concat(state.tenPartThreeList, state.twentyPartThreeList, state.thirtyPartThreeList, state.fortyPartThreeList);
      if (partThreeList) {
        for (var i = 0; i < partThreeList.length; i++) {
          numberList.push(partThreeList[i].number)
        }
        state.partThreeRandomNumber = _.sampleSize(numberList, 1);
      }
    }

    // 숫자: 범위 1 ~ 9
    const generateOneRandomNumber = () => {
      let numberList = [];
      let mergeList = state.onePartOneList.concat(state.onePartTwoList, state.onePartThreeList);
      if (mergeList) {
        for (var i = 0; i < mergeList.length; i++) {
          numberList.push(mergeList[i].number)
        }
        state.oneRandomNumber = _.sampleSize(numberList, 1);
      }
    }

    // 숫자: 범위 10 ~ 19
    const generateTenRandomNumber = () => {
      let numberList = [];
      let mergeList = state.tenPartOneList.concat(state.tenPartTwoList, state.tenPartThreeList);
      if (mergeList) {
        for (var i = 0; i < mergeList.length; i++) {
          numberList.push(mergeList[i].number)
        }
        state.tenRandomNumber = _.sampleSize(numberList, 1);
      }
    }

    // 숫자: 범위 20 ~ 29
    const generateTwentyRandomNumber = () => {
      let numberList = [];
      let mergeList = state.twentyPartOneList.concat(state.twentyPartTwoList, state.twentyPartThreeList);
      if (mergeList) {
        for (var i = 0; i < mergeList.length; i++) {
          numberList.push(mergeList[i].number)
        }
        state.twentyRandomNumber = _.sampleSize(numberList, 1);
      }
    }

    // 숫자: 범위 30 ~ 39
    const generateThirtyRandomNumber = () => {
      let numberList = [];
      let mergeList = state.thirtyPartOneList.concat(state.thirtyPartTwoList, state.thirtyPartThreeList);
      if (mergeList) {
        for (var i = 0; i < mergeList.length; i++) {
          numberList.push(mergeList[i].number)
        }
        state.thirtyRandomNumber = _.sampleSize(numberList, 1);
      }
    }

    // 숫자: 범위 40 ~ 45
    const generateFortyRandomNumber = () => {
      let numberList = [];
      let mergeList = state.fortyPartOneList.concat(state.fortyPartTwoList, state.fortyPartThreeList);
      if (mergeList) {
        for (var i = 0; i < mergeList.length; i++) {
          numberList.push(mergeList[i].number)
        }
        state.fortyRandomNumber = _.sampleSize(numberList, 1);
      }
    }

    const isNotEmpty = (arr) => {
      if (arr != null && arr != undefined) {
        return true;
      }
      return false;
    }

    const childAdfit = () => {
      let adfitDatas = [
        {
          dataAdUnit: 'DAN-ezDI4afvESPNMzqY',
          className: '.kakao-adfit-child1'
        },
        {
          dataAdUnit: 'DAN-YizDcktzt7MKzrqG',
          className: '.kakao-adfit-child2'
        }
      ];

      for (let i = 0; i < adfitDatas.length; i++) {
        let ins = document.createElement('ins');

        ins.className = 'kakao_ad_area';
        ins.style = "display:none; width:100%;";
        ins.setAttribute('data-ad-width', '300');
        ins.setAttribute('data-ad-height', '250');
        ins.setAttribute('data-ad-unit', adfitDatas[i].dataAdUnit);

        document.querySelector(adfitDatas[i].className).appendChild(ins);
      }
    }

    return {
      isWinningBonusNumberColor,
      getLotteryData,
      currencyWon,
      generateRandomNumberByConditionDatas,
      generateRandomNumberDatas,
      generateLuckyNumber,
      refreshCheckedUserNumbers,
      generateLuckyUserNumbers,
      generatePartOneRandomNumber,
      generatePartTwoRandomNumber,
      generatePartThreeRandomNumber,
      generateOneRandomNumber,
      generateTenRandomNumber,
      generateTwentyRandomNumber,
      generateThirtyRandomNumber,
      generateFortyRandomNumber,
      isNotEmpty,
      childAdfit,
      state
    }
  }
}
</script>

<style scoped>
.area-center {
  display: inline-flex;
  justify-items: center;
  max-width: 1000px;
}

.small-area-center {
  display: inline-flex;
  justify-items: center;
  max-width: 300px;
}

.table-header-center {
  justify-items: center;
  align-items: center;
}
</style>