import {createRouter, createWebHistory} from "vue-router";
import LotteryInfo from "@/components/topsix/LotteryInfo.vue";
import LotteryWinning from "@/components/topsix/LotteryWinning.vue";

const routes = [
    {
        path: "/",
        name: "로또 번호 뽑기",
        component: LotteryWinning
    },
    {
        path: "/topsix/lucky/number",
        name: "로또 당첨 정보",
        component: LotteryInfo
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;